import { LoginLayout } from "@main-zone/components/login-layout/login-layout";
import { LoginForm } from "@main-zone/forms/login-form/login-form";
import { InitialProps } from "@uxf/cms/pages/login-page/types";
import { NextPage } from "next";

export const LoginPage: NextPage<InitialProps> = () => (
    <LoginLayout pageTitle="Přihlášení" title="Přihlášení">
        <LoginForm />
    </LoginLayout>
);
