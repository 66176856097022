import { Helmet } from "@shared/components/helmet/helmet";
import Link from "next/link";
import { FC, PropsWithChildren, ReactNode } from "react";

export interface Props {
    title: ReactNode;
    description?: ReactNode;
    pageTitle: string;
}

export const LoginLayout: FC<PropsWithChildren<Props>> = (props) => {
    return (
        <div className="flex min-h-screen flex-col bg-white">
            <Helmet title={props.pageTitle} />
            <div className="flex h-[72px] items-center border-b border-lightBorder px-4 md:px-20 xl:px-28">
                <Link href="https://www.rehatab.cz/" target="_blank">
                    <img alt="RehaTab" className="w-[150px]" src="/images/logo.svg?v=2" />
                </Link>
            </div>
            <div className="flex grow flex-col items-center justify-center px-4 md:px-20 xl:px-28">
                <h1 className="uxf-typo-h4 mt-8">{props.title}</h1>
                {props.description && (
                    <p className="mt-3 w-[392px] max-w-full text-center text-lightMedium">{props.description}</p>
                )}
                <div className="mt-8 w-[392px] max-w-full">{props.children}</div>
            </div>
        </div>
    );
};
