import { config } from "@config/config";
import Head from "next/head";
import { memo, useEffect, useState } from "react";

export const Analytics = memo(() => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => setMounted(true), []);

    /*Aktuálně nepoužíváme naší cookie lištu*/
    /*const cookie = new Cookie();
    const consentCookie = cookie.get("cookieConsent");

    const decodedCookie: CookiesConsentType = JSON.parse(atob(consentCookie || "") || "{}");

    const defaultGtmCookie = `window.dataLayer = window.dataLayer || []; window.dataLayer.push({'dl.analytics_storage': '${
        decodedCookie.analytics ? "granted" : "denied"
    }', 'dl.ad_storage': '${decodedCookie.performance ? "granted" : "denied"}', 'dl.personalization_storage': '${
        decodedCookie.performance ? "granted" : "denied"
    }'});`;*/

    return (
        <Head>
            {config.GTM_ID && mounted && (
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            // defaultGtmCookie +
                            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${config.GTM_ID}');`,
                    }}
                />
            )}
        </Head>
    );
});

Analytics.displayName = "Analytics";
