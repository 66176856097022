import { routeToUrl } from "@app-routes";
import { useRouter } from "next/router";
import { memo } from "react";
import { Analytics } from "./analytics";
import { Favicon } from "./favicon";
import { MetaTagData, MetaTagDataProps } from "./meta-tag-data";
import { Prefetch } from "./prefetch";

interface Props {
    noIndex?: boolean;
    metaTagData?: Partial<Omit<MetaTagDataProps, "title" | "replaceFullTitle">>;
    replaceFullTitle?: boolean;
    title: string;
}

const ROUTES_WITH_ANALYTICS = [routeToUrl("main-zone/login", {}), routeToUrl("main-zone/registration")];

export const Helmet = memo<Props>(({ metaTagData = {}, noIndex, replaceFullTitle, title }) => {
    const { route } = useRouter();
    const { metaRobots, ...restMetaTagData } = metaTagData;

    const addAnalytics = ROUTES_WITH_ANALYTICS.includes(route);

    return (
        <>
            <Prefetch />
            <MetaTagData
                metaRobots={noIndex ? "noindex, nofollow" : metaRobots}
                replaceFullTitle={replaceFullTitle}
                title={title}
                {...restMetaTagData}
            />
            {addAnalytics && <Analytics />}
            <Favicon />
        </>
    );
});

Helmet.displayName = "Helmet";
